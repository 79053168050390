import Vue from 'vue';
import Vuex from 'vuex';
import projectStore from '@project/store';
import bugsnag from '@modules/bugsnag/m-bugsnag';
import authn from '@modules/authn/m-authn';
import device from '@modules/device/m-device';
import eid from '@modules/eid/m-eid';
import loading from '@modules/loading/m-loading';
import modal from '@modules/modal/m-modal';
import onboarding from '@modules/onboarding/m-onboarding';
import secure from '@modules/secure/m-secure';
import service from '@modules/service/m-service';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		authn,
		bugsnag,
		device,
		eid,
		loading,
		modal: modal(),
		onboarding,
		secure,
		service,
		...projectStore,
	},
});
