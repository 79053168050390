<template>
	<svg
		width="78px"
		height="78px"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<path
				d="M17.025 0H6.975C2.605 0 0 2.605 0 6.975v10.05C0 21.395 2.605 24 6.975 24h10.05C21.395 24 24 21.395 24 17.025V6.975C24 2.605 21.395 0 17.025 0z"
			/>
			<path
				d="M15.102 19c.618 0 1.12-.516 1.12-1.153s-.502-1.153-1.12-1.153c-.619 0-1.12.516-1.12 1.153S14.483 19 15.102 19zM9.98 19c.619 0 1.12-.516 1.12-1.153s-.501-1.153-1.12-1.153c-.619 0-1.12.516-1.12 1.153S9.362 19 9.98 19zM7.798 6.772 7.67 8.386a.517.517 0 0 0 .512.567h9.8c.27 0 .493-.21.512-.488.084-1.166-.78-2.114-1.914-2.114H8.726a1.817 1.817 0 0 0-.39-.798A1.656 1.656 0 0 0 7.113 5H5.98a.49.49 0 0 0-.48.494c0 .27.218.494.48.494h1.114c.198 0 .384.086.518.23.135.152.199.35.186.554zm10.03 3.17H8.01a.52.52 0 0 0-.512.48l-.23 2.866c-.09 1.12.768 2.088 1.863 2.088h7.118c.96 0 1.805-.81 1.875-1.798l.211-3.077a.51.51 0 0 0-.505-.56z"
				fill="#2D5372"
			/>
		</g>
	</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#Fill-42 {
	fill: $color-primary;
}
</style>
