let client = null;

export default {
	namespaced: true,

	actions: {
		install(store, instance) {
			client = instance;
		},

		log(store, { title, ...data }) {
			client.leaveBreadcrumb(title, data);
		},

		notify(store, e) {
			client.notify(e);
		},
	},
};
