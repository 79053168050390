<template>
	<div class="c-process-info">
		<div class="c-process-info__container">
			<img
				class="c-process-info__icon"
				src="@project/assets/img/card-icon.png"
				width="60"
				height="40"
				alt=""
			/>
			<img
				class="c-process-info__icon"
				src="@project/assets/img/cam-icon.png"
				width="34"
				height="40"
				alt=""
			/>
			<p class="c-process-info__desc">
				<span v-if="$slots.default"><slot /></span>
				<span v-else>
					Recuerda que durante el proceso necesitarás tener a mano el DNI y disponer de una
					cámara.
				</span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-process-info',
};
</script>

<style lang="scss" scoped>
.c-process-info__container {
	color: $color-surface-light;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
	border-radius: 10px;
	background-color: $color-primary;
	font-size: 12px;
	font-weight: 600;
}

.c-process-info__icon {
	margin-right: 10px;
}

.c-process-info__desc {
	margin-left: 10px;
	font-weight: 400;
}
</style>
