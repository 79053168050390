import { render, staticRenderFns } from "./app-choose-options.vue?vue&type=template&id=45b8573d&scoped=true&"
import script from "./app-choose-options.vue?vue&type=script&lang=js&"
export * from "./app-choose-options.vue?vue&type=script&lang=js&"
import style0 from "./app-choose-options.vue?vue&type=style&index=0&id=45b8573d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b8573d",
  null
  
)

export default component.exports