<template>
	<div class="c-downloads-row text-format-cbnk-1">
		<div class="c-downloads-row__container container">
			<h1 class="c-downloads-row__title text-format-cbnk-4">
				Controla tus finanzas fácilmente con nuestra app
			</h1>
			<p class="c-downloads-row__desc">
				Lleva tus cuentas en el bolsillo y accede a un clic a toda la información que
				<br />
				necesites. En CBNK estamos junto a ti siempre que lo desees.
			</p>
			<div class="c-downloads-row__actions">
				<div class="c-downloads-row__img">
					<a
						href="https://play.google.com/store/apps/details?id=es.grupocaminos.bancocaminos&hl=es_419"
						target="_blank"
					>
						<img
							src="@project/assets/img/google-download.png"
							width="241"
							height="70"
							alt=""
						/>
					</a>
				</div>
				<div class="c-downloads-row__img">
					<a
						href="https://apps.apple.com/us/app/banco-caminos/id1487466944"
						target="_blank"
					>
						<img
							src="@project/assets/img/apple-download.png"
							width="241"
							height="70"
							alt=""
						/>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-downloads-row',
};
</script>

<style lang="scss" scoped>
.c-downloads-row {
	display: flex;
	position: relative;
	width: 100%;
	flex-direction: column;
	color: $color-primary;
}

.c-downloads-row__title,
.c-downloads-row__desc {
	margin-bottom: 30px;
	text-align: center;
}

.c-downloads-row__desc {
	font-size: small;
}

.c-downloads-row__actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.c-downloads-row__img {
	padding: 10px;
}
</style>
