import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

const bugsnagAPIKey = 'fbc142f1a73b6c3296bed0b8b81248c8';
const bugsnagClient = Bugsnag.start({
	apiKey: bugsnagAPIKey,
	appVersion: window.VUE_APP_CONFIG.version || process.env.VUE_APP_VERSION,
	releaseStage: window.VUE_APP_CONFIG.env || process.env.VUE_APP_DEPLOY_ENVIRONMENT || 'dev',
	notifyReleaseStages: ['prd', 'tst'],
	navigationBreadcrumbsEnabled: false,
	plugins: [new BugsnagPluginVue(Vue)],
});

export default bugsnagClient;
