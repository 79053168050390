<template>
	<div class="c-landing-footer text-format-cbnk-2x">
		<div class="c-landing-footer__container container">
			<div class="c-landing-footer__column">
				<div class="c-landing-footer__logo">
					<img
						src="@project/assets/img/logo-claim.svg"
						alt=""
						width="100%"
						height="100%"
					/>
				</div>
			</div>
			<div class="c-landing-footer__column --center">
				<ul class="c-landing-footer__list">
					<li class="c-landing-footer__list-item">
						<a
							:href="urls.legalDisclaimer"
							target="_blank"
						>
							Aviso legal
						</a>
					</li>
					<li class="c-landing-footer__list-item">
						<a
							:href="urls.cookiePolicy"
							target="_blank"
						>
							Políticas de cookies
						</a>
					</li>
					<li class="c-landing-footer__list-item">
						<a
							:href="urls.legalInformation"
							target="_blank"
						>
							Información legal
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-landing-footer',

	computed: {
		urls() {
			return {
				legalDisclaimer: 'https://www.cbnk.es/aviso-legal',
				cookiePolicy: 'https://www.cbnk.es/aviso-legal#politica-de-cookies',
				legalInformation: 'https://www.cbnk.es/informacion-legal',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.c-landing-footer {
	display: flex;
	width: 100%;
}

.c-landing-footer__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 10px;
}

.c-landing-footer__column {
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.c-landing-footer__column.--center {
	justify-content: center;
}

.c-landing-footer__logo {
	display: flex;
	position: relative;
	width: 293px;
	height: 79px;
}

.c-landing-footer__list {
	color: $color-primary;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0px 10px;
	list-style: none;
}

.c-landing-footer__list-item a {
	color: $color-primary;
	user-select: none;
	position: relative;
}

.c-landing-footer__list-item:not(:last-of-type)::after {
	content: '|';
	padding: 10px;
}

.c-landing-footer__social {
	display: flex;
	justify-content: center;
	div {
		user-select: none;
		color: $color-primary;
		display: flex;
		width: 30px;
		height: 30px;
		margin: 10px;
		img {
			display: flex;
		}
	}
}

@media (max-width: 576px) {
	.c-landing-footer__container {
		justify-content: center;
	}
}

@media (max-width: 991.98px) {
	.c-landing-footer__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;
	}
}
</style>
