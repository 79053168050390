import { helpers } from 'vuelidate/lib/validators';

export const alpha = helpers.regex('alpha', /^[a-zA-ZÀ-ž\sºª-]*$/);

export const alphaNum = helpers.regex('alphaNum', /^[a-zA-Z0-9À-ž\sºª-]*$/);

export const postalCode = helpers.withParams({ type: 'postalCode' }, (value) => {
	const s = value.toString();
	return s.length === 5 && value <= 52999 && value >= 1000;
});

export const email = helpers.regex(
	'email',
	/^[a-zA-Z0-9&'*+-./=?^_{}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const typeahead = (prop, combo) =>
	helpers.withParams({ type: 'typeahead', prop, combo }, (id, parentVm) => {
		return (
			!helpers.req(id) ||
			helpers.ref(combo, this, parentVm)[id] === helpers.ref(prop, this, parentVm)
		);
	});
