const SET_ID = 'SET_ID';
const INCREASE_STEP = 'INCREASE_STEP';
const SET_NAME = 'SET_NAME';
const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
const SET_PRODUCT_ID = 'SET_PRODUCT_ID';

const documentType = {
	DNI: 62,
	NIE: 188,
};

export default {
	namespaced: true,

	state() {
		return {
			id: '',
			step: 1,
			stepCount: 8,
			clientName: '',
			documentType: 0,
			productId: 'CTATRANS',
		};
	},

	mutations: {
		[SET_ID](state, id) {
			state.id = id;
		},

		[INCREASE_STEP](state) {
			state.step += 1;
		},

		[SET_NAME](state, name) {
			state.clientName = name;
		},

		[SET_DOCUMENT_TYPE](state, value) {
			state.documentType = value;
		},

		[SET_PRODUCT_ID](state, value) {
			state.productId = value;
		},
	},

	actions: {
		async createProcess({ commit, dispatch, state }, data) {
			try {
				await dispatch('authn/login', null, { root: true });
			} catch (err) {
				throw new Error(err);
			}

			dispatch('loading/start', null, { root: true });

			const url = '/onboarding';
			const method = 'POST';
			const initialData = {
				stepCount: state.stepCount,
				step: state.step,
				...data,
			};

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						payload: initialData,
					},
					{ root: true }
				)
					.then((res) => {
						commit(SET_ID, res.data.id);
						commit(INCREASE_STEP);
						commit(SET_NAME, initialData.name);
						dispatch('loading/end', null, { root: true });
						resolve(res.data);
					})
					.catch((err) => {
						dispatch('loading/end', null, { root: true });
						reject(err);
					});
			});
		},

		saveProcess({ commit, dispatch, state }, data) {
			dispatch('loading/start', null, { root: true });

			const { id, step } = state;
			const initialData = { id, step, ...data };
			const url = `/onboarding/${id}`;
			const method = 'PATCH';

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						payload: initialData,
					},
					{ root: true }
				)
					.then((res) => {
						dispatch('loading/end', null, { root: true });
						commit(INCREASE_STEP);

						if (initialData?.idDocument?.type) {
							commit(SET_DOCUMENT_TYPE, documentType[initialData.idDocument.type]);
						}

						resolve(res);
					})
					.catch((err) => {
						dispatch('loading/end', null, { root: true });
						reject(err);
					});
			});
		},

		async getPlaces({ dispatch }, cod_postal) {
			const url = `/onboardings/places/${cod_postal}`;
			const method = 'GET';

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
					},
					{ root: true }
				)
					.then((res) => {
						resolve(res.data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},

		async getOffices({ dispatch }, id) {
			const url = `/onboardings/${id}/offices`;
			const method = 'GET';

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
					},
					{ root: true }
				)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},

		async getVerticals({ dispatch }) {
			const url = `/onboardings/verticals`;
			const method = 'GET';

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
					},
					{ root: true }
				)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},

		async getCollectives({ dispatch }, vertical) {
			const url = `/onboardings/collectives`;
			const method = 'GET';

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						queryParams: {
							vertical,
						},
					},
					{ root: true }
				)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},

		setProductId({ commit }, productId) {
			commit(SET_PRODUCT_ID, productId);
		},
	},
};
