const UUID_EXPIRED = 'C401000101';
const UUID_MISSED = 'C4010000';

export default {
	namespaced: true,

	actions: {
		async handle({ dispatch }, { error }) {
			if (error.code === 'ECONNABORTED' || !error.response) {
				return error;
			}

			const { errorCode } = error.response.data;

			if (errorCode === UUID_EXPIRED || errorCode === UUID_MISSED) {
				dispatch(
					'modal/open',
					{
						component: await import(
							/* webpackChunkName: "chunk-m-expired-session" */ '@modals/m-expired-session'
						),
					},
					{ root: true }
				);
			}

			return error;
		},
	},
};
