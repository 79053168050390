<template>
	<div class="c-compromise-row">
		<div class="c-compromise-row__container container">
			<h1 class="c-compromise-row__title">
				¿Tienes alguna duda? Ponte en contacto con nosotros
			</h1>
			<div class="c-compromise-row__actions">
				<div class="c-compromise-row__img">
					<img
						class="c-compromise-row__icon"
						src="@project/assets/img/callcenter.png"
						width="30"
						height="30"
						alt=""
					/>
					<p class="c-compromise-row__description">
						<span class="c-compromise-row">Contact Center</span>
						<span class="c-compromise-row --contact">
							913 109 550 / 900 107 411
							<br />
							correo@cbnk.es
							<br />
						</span>
						<span class="c-compromise-row">
							Lunes a viernes de 8:30 a 20h
							<br />
							Sábados de 9 a 14h
						</span>
					</p>
				</div>
				<div class="c-compromise-row__img">
					<img
						class="c-compromise-row__icon"
						src="@project/assets/img/whatsapp.png"
						width="28"
						height="28"
						alt=""
					/>
					<p class="c-compromise-row__description">
						<span class="c-compromise-row">Whatsapp</span>
						<span class="c-compromise-row --contact">
							628 500 200
							<br />
						</span>
						<span class="c-compromise-row">
							Lunes a viernes
							<br />
							de 8:30 a 19h
						</span>
					</p>
				</div>
				<div class="c-compromise-row__img">
					<img
						class="c-compromise-row__icon"
						src="@project/assets/img/location-icon.png"
						width="21"
						height="30"
						alt=""
					/>
					<p class="c-compromise-row__description">
						<span class="c-compromise-row">Oficinas</span>
						<span class="c-compromise-row --contact">
							Consulta teléfonos
							<br />
							horarios y
							<br />
							direcciones
							<a
								class="c-compromise-row-link"
								:href="urls.offices"
								target="_blank"
							>
								aquí
							</a>
						</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-compromise-row',

	computed: {
		urls() {
			return {
				offices: 'https://www.cbnk.es/oficinas',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.c-compromise-row {
	display: flex;
	position: relative;
	width: 100%;
	flex-direction: column;
	font-family: 'Figtree', georgia, serif;
	background: $color-primary;
	color: $color-primary-light;
}

.c-compromise-row__title {
	font-size: x-large;
	font-weight: 200;
	text-align: center;
	padding: 42px 0;
}

.c-compromise-row__actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-bottom: 32px;
}

.c-compromise-row__img {
	display: flex;
	gap: 12px;
	padding: 10px;
}

.c-compromise-row.--contact {
	color: $color-surface-light;
}

.c-compromise-row-link {
	color: $color-primary-light;
	text-decoration: underline;
}

@media (max-width: 576px) {
	.c-compromise-row__title {
		padding: 28px;
	}
}
</style>
