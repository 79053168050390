<template>
	<div class="m-register-form">
		<div class="m-register-form__header">
			<button
				class="m-register-form__close"
				type="button"
				aria-label="Close"
				data-testid="close-button"
				@click="closeModal"
			>
				&times;
			</button>
		</div>
		<div class="m-register-form__content">
			<div class="m-register-form__form">
				<c-landing-form @submit="submit"></c-landing-form>
			</div>
			<div class="m-register-form__process-info">
				<c-process-info></c-process-info>
			</div>

			<div class="m-register-form__login">
				<p>
					Si ya eres cliente, pulsa
					<a
						:href="url"
						title="Soy cliente"
						target="_blank"
					>
						aquí
					</a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import CLandingForm from '@components/c-cbnk-landing-form';
import CProcessInfo from '@project/components//c-process-info';

const { VUE_APP_CURRENT_PROJECT } = process.env;
const url = {
	caminos: 'https://www.bancocaminos.es/solicitudes/solicitud-cuenta-transparente-ya-cliente',
	bancofar: 'https://www.bancofar.es/solicitudes/solicitud-cuenta-transparente-ya-cliente',
}[VUE_APP_CURRENT_PROJECT];

export default {
	name: 'm-register-form',

	components: {
		CLandingForm,
		CProcessInfo,
	},

	data() {
		return {
			url,
			value: null,
		};
	},

	methods: {
		closeModal() {
			this.$emit('close');
		},

		submit($event) {
			this.value = $event;
			this.closeModal();
		},
	},
};
</script>

<style lang="scss" scoped>
.m-register-form {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: 40px 40px;
}

.m-register-form__header {
	display: flex;
	position: fixed;
	width: 48px;
	height: 48px;
	top: 0;
	right: 0;
	border-radius: 100%;
	background: $color-primary;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.m-register-form__close {
	user-select: none;
	appearance: none;
	outline: none;
	color: $color-surface-light;
	padding: 10px;
	border: 0;
	margin: 0;
	background: transparent;
	font-size: 32px;
}

.m-register-form__content {
	padding-top: 20px;
	overflow-y: auto;
}

.m-register-form__form {
	margin-bottom: 20px;
}

.m-register-form__process-info /deep/ .c-process-info .c-process-info__container {
	border-radius: 0;
	padding: 20px;
}

.m-register-form__login {
	color: $color-secondary;
	padding: 20px 10px;
	background: $color-surface-light;
	text-align: center;
	p {
		font-size: 1rem;
		margin: 0;
		a {
			text-transform: uppercase;
			color: white;
			font-weight: bold;
			margin-left: 10px;
			background-color: $color-primary;
			border-color: $color-secondary;

			display: inline-block;
			margin-bottom: 0;
			font-weight: normal;
			text-align: center;
			vertical-align: middle;
			touch-action: manipulation;
			cursor: pointer;
			background-image: none;
			border: 1px solid transparent;
			white-space: nowrap;
			padding: 6px 12px;
			font-size: 16px;
			line-height: 1.428571429;
			border-radius: 4px;
			user-select: none;
		}
	}

	@media screen and (max-device-width: 532px) {
		.m-register-form {
			padding: 20px 10px;
		}
	}
}
</style>
