<template>
	<div id="app-choose-options">
		<app-stepper :step-number="4"></app-stepper>
		<div>
			<div class="row flex-column align-items-center mainBox">
				<h2 class="mx-md-4 mx-3 mb-5 text-center text-format-1">
					{{ $store.state.onboarding.clientName }}, por último vamos a realizar la confirmación
					de tu identidad mediante un proceso de video-identificación, pero antes necesitamos
					que nos des tu consentimiento.
				</h2>
				<form class="d-flex flex-column align-items-center">
					<div class="d-flex flex-column justify-content-center mb-5">
						<div class="d-flex justify-content-center space-error mb-3">
							<span
								v-if="error"
								class="text-center text-danger"
							>
								Para continuar debe autorizar el tratamiento de sus datos biométricos
							</span>
						</div>
						<div class="container px-sm-5 d-flex justify-content-center pl-4 pr-4">
							<label class="containerBox">
								<input
									type="checkbox"
									v-model="authorizesBiometricDataTreatmentAndMaintenance"
								/>
								<span class="checkmarkBF"></span>
								<p class="block m-0 tf text-justify">
									Autorizo el tratamiento de mis datos biométricos con la finalidad de
									comprobar mi identidad, y la conservación del proceso durante el plazo
									legalmente establecido según la normativa de Prevención de Blanqueo de
									Capitales.
								</p>
							</label>
						</div>
					</div>
					<div class="confirm-button-group">
						<button
							class="button-confirm mb-5"
							type="submit"
							:disabled="isButtonDisabled && !authorizesBiometricDataTreatmentAndMaintenance"
							data-testid="confirm-button"
							@click.prevent="submit"
						>
							CONTINUAR
						</button>
					</div>
					<app-mobile-call></app-mobile-call>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import AppStepper from '@/components/app-stepper';
import AppMobileCall from '@/components/app-mobile-call';

export default {
	name: 'app-choose-options',

	components: {
		AppStepper,
		AppMobileCall,
	},

	data() {
		return {
			error: false,
			authorizesBiometricDataTreatmentAndMaintenance: false,
			isButtonDisabled: true,
		};
	},

	methods: {
		submit() {
			const { authorizesBiometricDataTreatmentAndMaintenance } = this;
			this.error = !authorizesBiometricDataTreatmentAndMaintenance;

			if (!this.error) {
				this.isButtonDisabled = true;
				this.$store
					.dispatch('onboarding/saveProcess', {
						authorizesBiometricDataTreatmentAndMaintenance,
					})
					.then(() => this.getEIDToken())
					.catch(() => {})
					.finally(() => {
						this.isButtonDisabled = false;
					});
			}
		},

		getEIDToken() {
			this.$store
				.dispatch('eid/init')
				.then(() => this.$router.push('app-camera-capture'))
				.catch(() => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 50px 0;
}
.containerBox {
	margin-top: 1px;
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
}
.containerBox p {
	font-size: 14px;
}
.containerBox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmarkBF {
	position: absolute;
	top: 0;
	left: 0;
	height: 21px;
	width: 21px;
	border: 2px solid #113456;
}
.containerBox input:checked ~ .checkmarkBF {
	background-color: #fff;
}
.checkmarkBF:after {
	content: '';
	position: absolute;
	display: none;
}
.containerBox input:checked ~ .checkmarkBF:after {
	display: block;
}
.containerBox .checkmarkBF:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 12px;
	border: solid $color-primary;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
.space-error {
	padding: 6px 0;
	position: relative;
}
.space-error span {
	font-size: 12px;
	position: absolute;
	top: -7px;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}

@media screen and (max-device-width: 480px) {
	img {
		width: 14px;
	}
	tbody {
		width: 90%;
	}
	td {
		padding-left: 0;
	}
}
@media screen and (max-width: 580px) {
	.button-confirm {
		width: 300px !important;
	}
}
</style>
