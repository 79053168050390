import axios from 'axios';
import MErrorHandler from './m-error-handler';

const parseStringTemplate = /* istanbul ignore next */ (str, obj) => {
	const parts = str.split(/\$\{(?!\d)[\wæøåÆØÅ]*\}/);
	const args = str.match(/[^{}]+(?=})/g) || [];
	const parameters = args.map(
		(argument) => obj[argument] || (obj[argument] === undefined ? '' : obj[argument])
	);
	return String.raw({ raw: parts }, ...parameters);
};

export default {
	namespaced: true,

	modules: { error: MErrorHandler },

	state() {
		return {
			req: axios.create(),
			baseURL:
				window.VUE_APP_CONFIG.endpoint ||
				process.env.VUE_APP_ENDPOINT ||
				'https://api.grupocaminos.es',
			timeout: 10000,
		};
	},

	actions: {
		async request({ rootState, state, dispatch }, { service, params, queryParams, payload }) {
			const request = { ...service.request };
			const requestData = {};

			if (payload) {
				const encryptedPayload = await dispatch('secure/encrypt', payload, {
					root: true,
				});
				requestData.data = { payload: encryptedPayload };
			}

			if (params) {
				request.url = parseStringTemplate(request.url, params);
			}

			return new Promise((resolve, reject) => {
				const defaultHeaders = {
					'Content-Type': 'application/json',
					'uuid': rootState.secure.uuid,
				};
				const headers = service.headers ?? defaultHeaders;
				const { baseURL, timeout } = state;

				const onSuccess = async (response) => {
					/* istanbul ignore next */
					if (window.VUE_APP_CONFIG.env === 'dev' || process.env.NODE_ENV === 'development') {
						const { groupCollapsed } = console;
						groupCollapsed(
							'%cOnboarding: ['
								.concat(response?.status, '] ')
								.concat(response?.config?.method.toUpperCase(), ' ')
								.concat(response?.config?.baseURL, response?.config.url),
							'color: #3eaf7c;'
						);
					}

					const { data } = response;

					if (data && typeof data === 'string') {
						const decData = await dispatch('secure/decrypt', data, {
							root: true,
						});

						// eslint-disable-next-line no-param-reassign
						response.data = decData;
					}

					/* istanbul ignore next */
					if (window.VUE_APP_CONFIG.env === 'dev' || process.env.NODE_ENV === 'development') {
						const { groupCollapsed, log, groupEnd } = console;

						groupCollapsed('Request (url)');
						log(response?.request?.responseURL);
						groupEnd();

						if (payload) {
							groupCollapsed('Request (data)');
							log(JSON.parse(JSON.stringify(payload)));
							groupEnd();
						}

						if (response.data) {
							groupCollapsed('Response');
							log(response.data);
							groupEnd();
						}

						groupEnd();
					}

					return resolve(response);
				};

				const onFailure = async (err) => {
					/* istanbul ignore next */
					if (window.VUE_APP_CONFIG.env === 'dev' || process.env.NODE_ENV === 'development') {
						const { groupCollapsed, log, groupEnd } = console;
						const status = err.response?.status;
						let method = err.response?.config?.method.toUpperCase();
						let url = `${err.response?.config?.baseURL}${err.response?.config.url}`;

						if (err?.description === 'Mirage: undefined') {
							[, method, url] = err.message.match(/tried to ([A-Z]*) '(.+)'/);
						}

						groupCollapsed(
							'%cOnboarding: ['.concat(status, '] ').concat(method, ' ').concat(url),
							'color: #3eaf7c;'
						);

						groupCollapsed('Request (url)');
						log(err.request?.responseURL);
						groupEnd();

						if (payload) {
							groupCollapsed('Request (data)');
							log(JSON.parse(JSON.stringify(payload)));
							groupEnd();
						}

						if (err.response) {
							groupCollapsed('Response');
							log(err.response);
							groupEnd();
						}

						groupEnd();
					}

					const error = await dispatch('error/handle', {
						error: err,
						requestConfig: {
							service,
							params,
							queryParams,
							payload,
						},
					});

					if (error?.config?.validateStatus(error?.status)) {
						return onSuccess(error);
					}

					return reject(error);
				};

				state.req
					.request({
						baseURL,
						headers,
						timeout,
						params: queryParams,
						...request,
						...requestData,
					})
					.then(onSuccess)
					.catch(onFailure);
			});
		},
	},
};
