<template>
	<div class="c-data-protection">
		<p class="c-data-protection__text text-center">
			INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
		</p>
		<p class="c-data-protection__text">
			RESPONSABLE: CBNK Banco de Colectivos S.A. (NIF A-28520666) Domicilio social en Calle
			Almagro nº8, 28010, Madrid. FINALIDAD: Alta y registro de los productos que le ofrecemos y
			en los que está interesado y/o adopción de medidas precontractuales. Gestionar la relación
			contractual de los productos o servicios contratados, en su caso. Realización de estudios y
			controles de fraude, impagos e incidencias. Resolución de sus dudas y consultas.
			Realización de manera proactiva de análisis de riesgos, aplicando sobre sus datos técnicas
			estadísticas y de segmentación para adaptar la oferta de productos y servicios a sus
			necesidades y/o preferencias, mediante el uso de decisiones automatizadas. Identificación y
			análisis de los riesgos que determinen la idoneidad de la contratación de los productos y/o
			servicios en los que muestre interés. Realización de encuestas de calidad y satisfacción.
			Enviar comunicaciones comerciales, por medios electrónicos o analógicos, relativos a
			productos y servicios bancarios y financieros comercializados por la Entidad. LEGITIMACIÓN:
			El consentimiento otorgado en este momento. DERECHOS: Acceso, rectificación, supresión,
			oposición, limitación del tratamiento y portabilidad de los datos. Puede ejercer estos
			derechos a través de
			<a
				class="c-data-protection__link"
				href="mailto:dpd@cbnk.es"
			>
				dpd@cbnk.es
			</a>
			. Igualmente tiene derecho a oponerse a que se adopten decisiones individuales
			automatizadas que produzcan efectos jurídicos sobre su persona.
		</p>
		<p class="c-data-protection__text">
			+ INFO: Si desea saber más sobre el tratamiento que realizamos de sus datos visite la
			<a
				class="c-data-protection__link"
				href="https://www.cbnk.es/aviso-legal#politica-de-privacidad"
				target="_blank"
			>
				Política de Privacidad
			</a>
			en la página web
			<a
				class="c-data-protection__link"
				href="http://www.cbnk.es/"
				target="_blank"
			>
				www.cbnk.es
			</a>
		</p>
	</div>
</template>

<script>
export default {
	name: 'c-data-protection',
};
</script>
"
<style lang="scss" scoped>
.c-data-protection__text {
	font-family: 'Open sans', arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	padding-bottom: 10px;
}

.c-data-protection__link {
	color: $color-secondary;
	text-decoration: underline;
}
</style>
