<template>
	<div>
		<div
			class="stepperContainer"
			:data-step="stepNumber"
		>
			<ul class="list-group list-group-horizontal list-unstyled">
				<li
					class="step1"
					:class="stepNumber > 0 ? '' : 'opa'"
				>
					<div class="step-format">
						<icon-datos-personales></icon-datos-personales>
					</div>
					<div class="text">Datos personales</div>
				</li>
				<li
					class="step2"
					:class="stepNumber > 2 ? '' : 'opa'"
				>
					<div class="step-format">
						<icon-datos-profesionales></icon-datos-profesionales>
					</div>
					<div class="text">Datos laborales</div>
				</li>
				<li
					class="step3"
					:class="stepNumber > 3 ? '' : 'opa'"
				>
					<div class="step-format">
						<icon-consentimientos></icon-consentimientos>
					</div>
					<div class="text">Consentimientos</div>
				</li>
				<li
					class="step4"
					:class="stepNumber > 5 ? '' : 'opa'"
				>
					<div class="step-format">
						<icon-identification></icon-identification>
					</div>
					<div class="text">Identificación</div>
				</li>

				<li
					class="step5"
					:class="stepNumber > 6 ? '' : 'opa'"
				>
					<div class="af step-format">
						<icon-market-place></icon-market-place>
					</div>
					<div class="text">Finalización</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import IconDatosPersonales from '@/assets/vue-svg/icon-datos-personales';
import IconDatosProfesionales from '@/assets/vue-svg/icon-datos-profesionales';
import IconConsentimientos from '@/assets/vue-svg/icon-consentimientos';
import IconIdentification from '@/assets/vue-svg/icon-identification';
import IconMarketPlace from '@/assets/vue-svg/icon-marketplace';

export default {
	name: 'app-stepper',

	components: {
		IconDatosPersonales,
		IconDatosProfesionales,
		IconConsentimientos,
		IconIdentification,
		IconMarketPlace,
	},

	props: {
		stepNumber: {
			type: Number,
			default: 1,
		},
	},
};
</script>

<style lang="scss" scoped>
.stepperContainer {
	padding-left: 12px;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	display: flex;
	justify-content: center;
	align-self: center;
	align-items: center;
}

.stepperContainer ul {
	margin-left: 70px;
}

.list-group li {
	width: 110px;
}

.step-format {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.step-format {
	position: relative;
	height: 40px;
	width: 45px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.step-format:after {
	position: absolute;
	top: calc(50% - 1px);
	left: 38px;
	height: 2px;
	content: '';
	width: 73px;
	border: 1px solid $color-primary;
}

.af:after {
	display: none;
}

.text {
	padding-top: 10px;
	width: 60px;
	text-align: center;
	line-height: 12px;
	margin-left: -12px;
	font-weight: 400;
	color: #113456;
	font-size: 10px;
}

.opa {
	opacity: 0.3;
}

@media screen and (max-device-width: 480px) {
	.stepperContainer ul {
		margin: 0 auto;
	}
	.list-group li {
		width: 80px;
	}
	.step-format {
		height: 42px;
		width: 41px;
	}
	.step-format:after {
		position: absolute;
		top: calc(50% - 1px);
		left: 40px;
		height: 2px;
		content: '';
		width: 40px;
		border: 1px solid $color-primary;
	}
	.text {
		width: 50px;
		font-size: 10px;
		margin-left: -5px;
	}

	[data-step='1'] .step4,
	[data-step='2'] .step4,
	[data-step='3'] .step5,
	[data-step='4'] .step5,
	[data-step='5'] .step5 {
		width: 46px;
	}
}
@media screen and (max-width: 800px) {
	.stepperContainer {
		width: 100%;
		justify-content: flex-start;
	}
	.stepperContainer ul {
		margin: 0 auto;
	}
	.list-group li {
		width: 80px;
	}
	.step-format {
		height: 42px;
		width: 41px;
	}
	.step-format::after {
		position: absolute;
		top: calc(50% - 1px);
		left: 40px;
		height: 1px;
		content: '';
		width: 40px;
		border: 1px solid $color-primary;
	}
}
</style>
