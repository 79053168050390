<template>
	<div class="c-cbnk-product-risk text-format-cbnk-1">
		<div class="c-cbnk-product-risk__level">
			<div class="c-cbnk-product-risk__giant">
				<span>1</span>
				<strong>/6</strong>
			</div>
			<div class="c-cbnk-product-risk__desc">
				<p>
					Este número es indicativo del riesgo del producto, siendo
					<br />
					1/6 indicativo de menor riesgo y 6/6 de mayor riesgo.
				</p>
			</div>
		</div>
		<div class="c-cbnk-product-risk__legal">
			<p>
				{{ $store.state.app.companyName }} está adscrito al Fondo de Garantía de Depósitos
				Español. Para depósitos en dinero el importe máximo garantizado es de 100.000 euros por
				depositante en cada entidad de crédito.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-cbnk-product-risk',
};
</script>

<style lang="scss" scoped>
.c-cbnk-product-risk {
	display: flex;
	position: relative;
	width: 100%;
	margin: 0;
	border: 1px solid $color-risk-dark;
	background: $color-surface-light;
	overflow: hidden;
	color: $color-risk-dark;
}

.c-cbnk-product-risk__level,
.c-cbnk-product-risk__legal {
	float: left;
	width: 50%;
	padding: 5px;
}

.c-cbnk-product-risk__level {
	border-right: 0.5px solid $color-risk-light;
}

.c-cbnk-product-risk__legal {
	padding: 18px;
	border-left: 0.5px solid $color-risk-light;
}

.c-cbnk-product-risk__giant {
	text-align: center;
	font-size: 2rem;
	font-weight: 600;
	& :first-child {
		color: #888;
	}
	& :last-child {
		color: #000;
	}
}

.c-cbnk-product-risk__desc {
	display: flex;
	justify-content: center;
}

.c-cbnk-product-risk__legal p,
.c-cbnk-product-risk__desc p {
	font-size: 10px;
	margin: 0;
}
</style>
