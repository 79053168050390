<template>
	<div class="m-requirements">
		<div class="modal-header">
			<button
				data-test-id="close-button"
				@click="closeModal"
				aria-label="Close"
				class="close"
				type="button"
			>
				<span aria-hidden="true">x</span>
			</button>
		</div>
		<div class="modal-body">
			<h2 class="text-center mb-5">Requisitos</h2>
			<ul>
				<li>Soy mayor de edad y tengo mi residencia fiscal en España.</li>
				<li>
					No tengo responsabilidades fiscales fuera de España y no soy ciudadano de EE.UU.
				</li>
				<li>El propósito de esta cuenta es de ahorro y la operativa del día a día.</li>
				<li>Soy un solo titular.</li>
				<li>
					No tengo ni he tenido cargos de responsabilidad pública en los últimos 2 años ni soy
					familiar o allegado de alguien que la ha tenido.
				</li>
			</ul>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				class="button-confirm txt-uc"
				type="button"
				@click="closeModal"
			>
				ACEPTAR
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-requirements',

	methods: {
		closeModal() {
			this.$store.dispatch('modal/close');
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-header button {
	outline: none;
}

.modal-body {
	max-height: 40vh;
	padding: 0 3rem;
	overflow-x: auto;
}

.modal-body p:not(:first-of-type) {
	margin-top: 10px;
}

.modal-footer {
	padding: 2rem;
}
</style>
