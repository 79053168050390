export const TEXT_REQUIRED = 'Este campo es obligatorio';
export const TEXT_MAX_LENGTH = (max) => `Ingrese ${max} carácteres como máximo.`;
export const TEXT_EMAIL_DONT_MATCH = 'Email no coincide';
export const TEXT_NUMERIC = 'Número no válido';
export const TEXT_EMAIL = 'Email no válido';
export const TEXT_MIN_VALUE = 'Si es menor de edad no podrá seguir con el proceso';
export const TEXT_INVALID_PHONE_NUMBER = 'Número de teléfono no válido';
export const TEXT_INVALID_DOCUMENT_NUMBER = 'El documento no parece ser válido';
export const TEXT_ALPHA = 'El campo contiene carácteres inválidos';
export const TEXT_POSTAL_CODE = 'Código postal inválido';
export const TEXT_CNO_INVALID = 'Introduzca una profesión válida';
export const TEXT_CNAE_INVALID = 'Introduzca una actividad válida';
