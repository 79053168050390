import publicService from '@modules/service/m-public-service';
import { generateKeyPair, decryptRSA, encryptAES, decryptAES, getAESKey } from './cypher';

const SET_SESSION_DATA = 'SET_SESSION_DATA';
const CLEAN_SESSION_DATA = 'CLEAN_SESSION_DATA';
const SET_AES_KEY = 'SET_AES_KEY';

export default {
	namespaced: true,

	modules: { publicService },

	/**
	 * @typedef {Object} state
	 * @property {Object} keyPair Crypto Key pair
	 * @property {String} publicKey Base64 encoded public key
	 * @property {Object} aesKey Crypto Symmetric Key
	 * @property {String} seed Base64 encoded seed for AES
	 * @property {String} symmetricKey Base64 encoded key for AES
	 * @property {String} uuid Unique Session Id
	 */
	state() {
		return {
			aesKey: null,
			seed: null,
			symmetricKey: null,
			uuid: null,
			identity: null,
		};
	},

	mutations: {
		[SET_SESSION_DATA](state, { seed, symmetricKey, uuid }) {
			state.seed = seed;
			state.symmetricKey = symmetricKey;
			state.uuid = uuid;
			state.identity = Symbol(uuid);
			state.aesKey = null;
		},

		[CLEAN_SESSION_DATA](state) {
			state.seed = null;
			state.symmetricKey = null;
			state.uuid = null;
			state.identity = null;
			state.aesKey = null;
		},

		[SET_AES_KEY](state, key) {
			state.aesKey = key;
		},
	},

	actions: {
		/**
		 * Create a new uuid session.
		 * @param {Object} store
		 * @param {commit} store.commit
		 * @param {dispatch} store.dispatch
		 * @param {rootState} store.rootState
		 */
		async createSession({ commit, dispatch, rootState }) {
			const { privateKey, publicKey } = await generateKeyPair();
			const url = '/key-exchange';
			const method = 'POST';
			const { baseURL } = rootState.service;
			const headers = {
				'Content-Type': 'application/json',
				'public-key': publicKey,
			};

			return new Promise((resolve, reject) => {
				dispatch('publicService/request', {
					url,
					method,
					baseURL,
					headers,
				})
					.then(async ({ data }) => {
						if (data) {
							const response = await decryptRSA(privateKey, data);

							commit(SET_SESSION_DATA, response);

							return resolve(response);
						}

						reject(data);
					})
					.catch(reject);
			});
		},

		/**
		 * Remove current session.
		 * @param {Object} store
		 * @param {commit} store.commit
		 */
		removeSession({ commit }) {
			commit(CLEAN_SESSION_DATA);
		},

		/**
		 * Destroy and create a new uuid session.
		 * @param {Object} store
		 * @param {dispatch} store.dispatch
		 */
		refreshSession({ dispatch }) {
			dispatch('removeSession');

			return dispatch('createSession');
		},

		/**
		 * Returns the crypto AES Key.
		 * @param {Object} store
		 * @param {dispatch} store.dispatch
		 * @param {commit} store.commit
		 * @param {state} store.state
		 * @returns {Object} Crypto AES Key.
		 */
		async getAESKey({ dispatch, commit, state }) {
			/* istanbul ignore next */
			if (!state.uuid) {
				await dispatch('createSession');
			}

			const { symmetricKey, aesKey } = state;

			if (!aesKey) {
				const cryptoKey = await getAESKey(symmetricKey);

				commit(SET_AES_KEY, cryptoKey);

				return cryptoKey;
			}

			return aesKey;
		},

		/**
		 * Encrypt data with the symmetric key.
		 * @param {store} store
		 * @param {state} store.state
		 * @param {dispatch} store.dispatch
		 * @param {Object} data Data to encrypt
		 * @returns {String} A base64 encrypted data
		 */
		async encrypt({ state, dispatch }, data) {
			const key = await dispatch('getAESKey');
			const { seed } = state;

			return encryptAES({
				seed,
				key,
				data,
			});
		},

		/**
		 * Decrypt a base64 string with the symmetric key.
		 * @param {store} store
		 * @param {state} store.state
		 * @param {dispatch} store.dispatch
		 * @param {String} data A base64 string data to decrypt.
		 * @returns {Object} A clean payload.
		 */
		async decrypt({ state, dispatch }, data) {
			const key = await dispatch('getAESKey');
			const { seed } = state;

			return decryptAES({
				seed,
				key,
				data,
			});
		},
	},
};
