<template>
	<div
		class="app-landing"
		:class="{ '--is-cbnk': $store.state.app.isCBNK }"
	>
		<div
			v-if="$store.state.onboarding.productId === 'CTATRANS'"
			class="app-landing__main"
		>
			<c-header class="app-landing__header" />

			<section class="app-landing__section --payments">
				<c-payments-row />
			</section>

			<section class="app-landing__section --downloads">
				<c-downloads-row />
			</section>

			<section class="app-landing__section --commissions">
				<c-commissions-row />
			</section>

			<section class="app-landing__section --compromise">
				<c-compromise-row />
			</section>

			<footer class="app-landing__section --footer">
				<c-landing-footer />
			</footer>
		</div>

		<div
			v-else
			class="app-landing__main"
		>
			<c-header-distancia />

			<footer class="app-landing__section --footer">
				<c-landing-footer />
			</footer>
		</div>
	</div>
</template>

<script>
import CLandingFooter from '@project/components/c-landing-footer';
import CHeader from '@project/components/c-header';
import CPaymentsRow from '@project/components/c-payments-row';
import CDownloadsRow from '@project/components/c-downloads-row';
import CCommissionsRow from '@project/components/c-commissions-row';
import CCompromiseRow from '@project/components/c-compromise-row';
import CHeaderDistancia from '@project/components/c-header-distancia';

export default {
	name: 'app-landing',

	components: {
		CHeader,
		CLandingFooter,
		CPaymentsRow,
		CDownloadsRow,
		CCommissionsRow,
		CCompromiseRow,
		CHeaderDistancia,
	},
};
</script>

<style lang="scss" scoped>
.app-landing {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.app-landing.--is-cbnk {
	.app-landing__header,
	.app-landing__section.--payments,
	.app-landing__section.--commissions,
	.app-landing__section.--footer {
		background: $color-surface-light;
	}

	.app-landing__section.--downloads {
		background: $color-surface-dark;
	}

	.app-landing__main {
		background: $color-surface-light;
	}

	@media (max-width: 576px) {
		.app-landing__section.--payments,
		.app-landing__section.--footer {
			padding-top: 0;
		}
	}
}

.app-landing__main {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.app-landing__section {
	display: flex;
	width: 100%;
	padding-top: 40px;
	padding-bottom: 40px;
}

.app-landing__section.--payments {
	padding-top: 80px;
	background: $color-surface-dark;
}

.app-landing__section.--downloads {
	background: $color-surface-light;
}

.app-landing__section.--commissions {
	background: $color-surface-dark;
}

.app-landing__section.--compromise {
	padding: 0;
	background: $color-surface-light;
}

.app-landing__section.--footer {
	background: $color-secondary;
}
</style>
