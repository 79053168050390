<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">AVISO</h2>
			<p class="text-format-2x text-center px-3">
				<span
					data-test-id="safari"
					v-if="safari && !ie"
				>
					Para realizar nuestro onboarding de una manera efectiva te pedimos que cambies de
					navegador a Safari.
				</span>
				<span
					data-test-id="ie"
					v-if="!safari && ie"
				>
					Se ha detectado que usted esta usando Internet Explorer. Le rogamos por favor cambie
					de navegador para poder realizar el proceso de Onboarding.
					<p>
						Puede descargar
						<a
							tabindex="-1"
							href="https://www.google.com/intl/es_es/chrome/"
							target="_blank"
						>
							Chrome
						</a>
						,
						<a
							tabindex="-1"
							href="https://www.mozilla.org/es-ES/firefox/new/"
							target="_blank"
						>
							Firefox
						</a>
						o
						<a
							tabindex="-1"
							href="https://www.microsoft.com/es-es/edge"
							target="_blank"
						>
							Microsoft Edge
						</a>
						para continuar.
					</p>
				</span>
				<span
					data-test-id="default"
					v-if="!safari && !ie"
				>
					Para realizar nuestro onboarding de una manera efectiva te pedimos que cambies de
					navegador.
				</span>
			</p>
			<p class="text-format-2x text-center px-3">Muchas gracias.</p>
		</div>
		<div class="modal-footer justify-content-center"></div>
	</div>
</template>

<script>
export default {
	name: 'm-browser-support',

	props: {
		safari: {
			type: Boolean,
			default: false,
		},
		ie: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
