const SET_EID_ID = 'SET_EID_ID';
const SET_EID_TOKEN = 'SET_EID_TOKEN';
const FILL_EID_DATA = 'FILL_EID_DATA';

export default {
	namespaced: true,

	state() {
		return {
			token: '',
			data: {},
			id: '',
		};
	},

	mutations: {
		[SET_EID_ID](state, id) {
			state.id = id;
		},

		[SET_EID_TOKEN](state, token) {
			state.token = token;
		},

		[FILL_EID_DATA](state, data) {
			state.data = data;
		},
	},

	actions: {
		init({ commit, dispatch, rootState }) {
			dispatch('loading/start', null, { root: true });

			const { id } = rootState.onboarding;
			const url = `/onboarding/${id}/eid`;
			const method = 'POST';

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
					},
					{ root: true }
				)
					.then(({ data }) => {
						dispatch('loading/end', null, { root: true });
						commit('SET_EID_TOKEN', data.authorization);
						commit('SET_EID_ID', data.id);
						resolve(data);
					})
					.catch((err) => {
						dispatch('loading/end', null, { root: true });
						reject(err);
					});
			});
		},

		getData({ commit, dispatch, rootState, state }) {
			dispatch('loading/start', null, { root: true });

			const { id } = state;
			const { id: onboardingId } = rootState.onboarding;
			const url = `/onboarding/${onboardingId}/eid/${id}`;
			const method = 'GET';

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
					},
					{ root: true }
				)
					.then(({ data }) => {
						dispatch('loading/end', null, { root: true });
						commit('FILL_EID_DATA', data);
						resolve(data);
					})
					.catch((err) => {
						dispatch('loading/end', null, { root: true });
						reject(err);
					});
			});
		},
	},
};
