<template>
	<div class="m-consent-tgss">
		<div class="modal-header">
			<button
				data-test-id="close-button"
				@click="closeModal"
				aria-label="Close"
				class="close"
				type="button"
			>
				<span aria-hidden="true">×</span>
			</button>
		</div>
		<div class="modal-body">
			<p>
				{{ companyName }} informa que la legislación vigente sobre prevención de blanqueo de
				capitales obliga a las entidades bancarias a obtener de sus clientes información de su
				actividad económica y a realizar una comprobación de la misma.
			</p>
			<p>
				Con este exclusivo fin de verificación de la información finalizada, se presta
				consentimiento expreso a {{ companyName }} para que en nombre del usuario pueda
				solicitar ante la Tesorería General de la Seguridad Social dicha información.
			</p>
			<p>
				Los datos obtenidos de la Tesorería General de la Seguridad Social serán utilizados
				exclusivamente para la gestión señalada anteriormente. En caso de incumplimiento de esta
				obligación por parte de la entidad financiera y/o del personal que en ella presta
				servicios, se ejecutarán todas las actuaciones previstas en la Ley Orgánica 3/2018, de 5
				de diciembre, de Protección de Datos Personales y garantías de derechos digitales
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				class="button-confirm txt-uc"
				type="button"
				@click="closeModal"
			>
				ACEPTAR
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-consent-tgss',

	props: { companyName: String },

	methods: {
		closeModal() {
			this.$store.dispatch('modal/close');
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-header button {
	outline: none;
}

.modal-body {
	max-height: 40vh;
	padding: 0 3rem;
	overflow-x: auto;
}

.modal-body p:not(:first-of-type) {
	margin-top: 10px;
}

.modal-footer {
	padding: 2rem;
}
</style>
