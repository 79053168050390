<template>
	<div class="c-cbnk-form-distancia">
		<div class="c-cbnk-form-distancia__container">
			<div class="c-cbnk-form-distancia__description">
				<p class="c-cbnk-form-distancia__desc-1">
					En CBNK trabajamos día a día para hacer que tu
					<br />
					experiencia con nosotros sea lo más accesible, cómoda
					<br />
					e intuitiva posible. En definitiva, transparente. Rellena el
					<br />
					formulario e inicia nuestro proceso de alta a distancia.
				</p>
				<div class="c-cbnk-form-distancia__process-info">
					<c-process-info />
				</div>
			</div>
			<div class="c-cbnk-form-distancia__content">
				<form
					class="c-cbnk-form-distancia__form"
					@submit.prevent="submit"
				>
					<div class="c-cbnk-form-distancia__form-content">
						<div class="c-cbnk-form-distancia__form-group">
							<c-text-field
								v-model.trim="$v.name.$model"
								:placeholder="'Nombre'"
								autocomplete="given-name"
								:invalid="$v.name.$dirty && $v.name.$error"
								@blur="$v.name.$touch()"
							>
								<span slot="title">Nombre</span>
							</c-text-field>
						</div>
						<div class="c-cbnk-form-distancia__form-group">
							<c-text-field
								v-model.number.trim="$v.mobilePhoneNumber.$model"
								autocomplete="tel"
								maxlength="9"
								:invalid="$v.mobilePhoneNumber.$dirty && $v.mobilePhoneNumber.$error"
								@blur="$v.mobilePhoneNumber.$touch()"
							>
								<span slot="title">Teléfono</span>
							</c-text-field>
						</div>
						<div class="c-cbnk-form-distancia__form-group">
							<c-text-field
								v-model.trim="$v.email.$model"
								:placeholder="'E-mail'"
								autocomplete="email"
								:invalid="$v.email.$dirty && $v.email.$error"
								@blur="$v.email.$touch()"
							>
								<span slot="title">Correo electrónico</span>
							</c-text-field>
						</div>
						<div class="c-cbnk-form-distancia__form-group">
							<c-text-field
								v-model.trim="$v.emailV.$model"
								:placeholder="'Confirma tu e-mail'"
								autocomplete="email"
								:invalid="$v.emailV.$dirty && $v.emailV.$error"
								@blur="$v.emailV.$touch()"
							>
								<span slot="title">Repite tu correo electrónico</span>
							</c-text-field>
						</div>
					</div>
					<div
						class="c-cbnk-form-distancia__checkbox"
						:class="{ '--error': condicionesError }"
					>
						<label
							class="c-cbnk-form-distancia__checkbox-label"
							for="initial-data-condiciones"
						>
							<input
								id="initial-data-condiciones"
								class="c-cbnk-form-distancia__checkbox-mark"
								type="checkbox"
								v-model="$v.condiciones.$model"
							/>
							Acepto la
							<a
								class="c-cbnk-form-distancia__checkbox-link"
								:href="securityPolicyLink"
								target="_blank"
							>
								política de privacidad y protección de datos
							</a>
						</label>
					</div>

					<div class="c-cbnk-form-distancia__data-protection">
						<div class="c-cbnk-form-distancia__data-protection-scrolling">
							<c-data-protection></c-data-protection>
						</div>
					</div>

					<button
						class="c-cbnk-form-distancia__submit-button"
						type="submit"
						:disabled="isButtonDisabled"
					>
						Contratar
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { alpha, email } from '@utils/validators';
import { mapErrorFields } from '@utils/helpers';
import { minLength, required, maxLength, numeric, helpers } from 'vuelidate/lib/validators';
import MRequirements from '@modals/m-requirements';
import MConsentTgss from '@modals/m-consent-tgss';
import CProcessInfo from '@project/components/c-process-info';
import CTextField from '@components/c-cbnk-text-field';
import CDataProtection from '@project/components/c-data-protection';

const { VUE_APP_CURRENT_PROJECT } = process.env;

const sameAsEmail = (equalTo) => {
	return helpers.withParams({ type: 'sameAs', eq: equalTo }, function (value, parentVm) {
		const lowerCaseEmailV = value?.toLowerCase();
		const emailValue = helpers.ref(equalTo, this, parentVm);

		return lowerCaseEmailV === emailValue?.toLowerCase();
	});
};

export default {
	name: 'c-cbnk-form-distancia',

	components: { CTextField, CDataProtection, CProcessInfo },

	props: { isButtonDisabled: Boolean },

	data() {
		return {
			name: '',
			mobilePhoneNumber: '',
			email: '',
			emailV: '',
			requisitos: false,
			condiciones: false,
		};
	},

	validations: {
		name: {
			alpha,
			required,
			maxLength: maxLength(45),
		},
		mobilePhoneNumber: {
			required,
			numeric,
			phoneMinLength: minLength(9),
			maxLength: maxLength(9),
			phoneNumber: helpers.withParams({ type: 'phoneNumber' }, (value) => {
				const s = `${value}`;
				return s.startsWith('6') || s.startsWith('7');
			}),
		},
		email: {
			required,
			email,
			maxLength: maxLength(255),
		},
		emailV: {
			required,
			sameAsEmail: sameAsEmail('email'),
		},
		requisitos: {
			accepted: (value) => value === true,
		},
		condiciones: {
			accepted: (value) => value === true,
		},
	},

	computed: {
		...mapErrorFields([
			'name',
			'mobilePhoneNumber',
			'email',
			'emailV',
			'requisitos',
			'condiciones',
		]),

		securityPolicyLink() {
			return 'https://www.cbnk.es/aviso-legal#politica-de-privacidad';
		},

		urls() {
			return {}[VUE_APP_CURRENT_PROJECT];
		},
	},

	methods: {
		submit() {
			// eslint-disable-next-line no-shadow
			const { name, mobilePhoneNumber, email, emailV, $v } = this;

			if (this.$store.state.onboarding.productId === 'DISTANCIA') {
				this.requisitos = true;
			}

			$v.$touch();

			if (!$v.$invalid) {
				this.$emit('submit', {
					name,
					mobilePhoneNumber,
					email: email?.toLowerCase(),
					emailV: emailV?.toLowerCase(),
				});
			}
		},

		openRequirementsModal() {
			return this.$store.dispatch('modal/open', MRequirements);
		},

		openConsentModal() {
			return this.$store.dispatch('modal/open', {
				component: MConsentTgss,
				props: { companyName: this.$store.state.app.companyName },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.c-cbnk-form-distancia {
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.c-cbnk-form-distancia__container {
	display: grid;
	grid-template-columns: 35% 65%;
	gap: 60px;
}

.c-cbnk-form-distancia__form {
	max-width: 850px;
}

.c-cbnk-form-distancia__form-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	max-width: 850px;
	row-gap: 20px;
	margin-bottom: 25px;
}

.c-cbnk-form-distancia__data-protection {
	display: flex;
	position: relative;
	width: 100%;
	height: 100px;
	margin-top: 20px;
	background: $color-disabled;
	overflow: hidden;
}

.c-cbnk-form-distancia__data-protection-scrolling {
	overflow-x: hidden;
	overflow-y: scroll;
}

.c-cbnk-form-distancia__desc-1 {
	margin-bottom: 25px;
}

.c-cbnk-form-distancia__process-info {
	display: flex;
	position: relative;
	width: 400px;
}

.c-cbnk-form-distancia__form-group {
	max-width: 400px;
}

.c-cbnk-form-distancia__submit-button {
	outline: none;
	user-select: none;
	appearance: none;
	white-space: nowrap;
	color: $color-surface-light;
	display: block;
	width: 120px;
	border: 0;
	border-radius: 20px;
	padding: 10px 12px;
	margin-top: 60px;
	background-color: $color-primary;
	font-size: small;
	font-weight: 600;
}

.c-cbnk-form-distancia__submit-button:disabled {
	color: $color-text-disabled;
	background-color: $color-disabled;
}

.c-cbnk-form-distancia__checkbox-mark {
	margin-right: 5px;
}

.c-cbnk-form-distancia__description {
	padding: 10px 0;
}

.c-cbnk-form-distancia__checkbox-link {
	color: $color-secondary;
	text-decoration: underline;
}

@media (max-width: 1620px) and (min-width: 992px) {
	.c-cbnk-form-distancia__container {
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 992px) {
	.c-cbnk-form-distancia__container {
		display: flex;
		flex-direction: column;
	}
}
</style>
