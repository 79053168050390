<template>
	<div class="c-cbnk-test-field">
		<div
			v-if="$slots.title"
			class="c-cbnk-test-field__title"
		>
			<slot name="title" />
		</div>
		<div
			class="c-cbnk-test-field__field"
			:class="{ '--error': invalid }"
		>
			<label
				class="c-cbnk-test-field__label"
				for="c-cbnk-test-field__name"
			>
				{{ label }}
			</label>
			<input
				id="c-cbnk-test-field__name"
				class="c-cbnk-test-field__input"
				v-model="inputValue"
				:placeholder="placeholder"
				type="text"
				v-bind="$attrs"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-cbnk-test-field',

	model: {
		prop: 'value',
		event: 'update:value',
	},

	props: {
		label: { type: String },
		value: { type: [String, Number] },
		invalid: { type: Boolean },
		placeholder: { type: String },
	},

	computed: {
		inputValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('update:value', value);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.c-cbnk-test-field {
	display: flex;
	position: relative;
	flex-direction: column;
}

.c-cbnk-test-field__title {
	display: flex;
	position: relative;
	margin-top: 12px;
	margin-bottom: 5px;
	font-size: 0.75rem;
	font-weight: 400;
}

.c-cbnk-test-field__field {
	display: flex;
	position: relative;
	width: 100%;
	align-items: center;
	border: 1px solid #c8c8c8;
	border-radius: 2px;
	padding: 6px 10px;
	background-color: white;
}

.c-cbnk-test-field__field.--error {
	border-color: #fb5f58;
}

.c-cbnk-test-field__label {
	display: flex;
	position: relative;
	flex-shrink: 0;
	margin: 0;
	font-weight: 400;
}

.c-cbnk-test-field__label:not(:empty) {
	padding-right: 5px;
}

.c-cbnk-test-field__input {
	appearance: none;
	user-select: none;
	position: relative;
	width: 100%;
	border: 0;
	background: transparent;
	font-size: 0.75rem;
}

.c-cbnk-test-field__input:focus-visible {
	outline: none;
}
</style>
