export default {
	namespaced: true,

	actions: {
		async login({ dispatch, rootState }) {
			await dispatch('secure/createSession', null, { root: true });
			dispatch('loading/start', null, { root: true });

			const url = '/login';
			const method = 'POST';

			const credentials = {
				companyId: rootState.app.companyId,
				channel: 'WEB',
				deviceId: rootState.device.id,
			};

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						payload: credentials,
					},
					{ root: true }
				)
					.then(({ data }) => resolve(data))
					.catch((err) => reject(err));
			});
		},
	},
};
